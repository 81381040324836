import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Flex } from '@rebass/grid';
import { display } from 'styled-system';

import Button from '../Button';
import Link from '../Link';

import { PYSZNE_LINK, NAV_ITEMS } from '../../constants';

const NavItem = ({ to, chosen, label, disabled }) => (
  <Button.Text as={Link} to={to} chosen={chosen} disabled={disabled}>
    <FormattedMessage id={`nav.${label}`} />
  </Button.Text>
);

const Desktop = ({ currentPage, intl }) => {
  const isDisabledEnMenu = (label) => label === 'menu' && intl.locale === 'en';

  return (
    <Flex
      css={`
        ${display}
      `}
      justifyContent="center"
      mt={35}
      display={{ mobile: 'none', laptop: 'flex' }}
    >
      <Flex as="nav">
        {NAV_ITEMS.map(({ to, label }) => (
          <NavItem
            key={label}
            to={to}
            label={label}
            chosen={currentPage === label}
            disabled={isDisabledEnMenu(label)}
          />
        ))}
        <Button
          as={Link}
          external
          to={PYSZNE_LINK}
          small
          css={`
            margin-left: 3rem;
          `}
        >
          <FormattedMessage id="nav.order" />
        </Button>
      </Flex>
    </Flex>
  );
};

Desktop.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

export default injectIntl(Desktop);
