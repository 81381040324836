import React from 'react';

const Error8 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-8 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="194.803"
      height="297.47"
    >
      <defs />
      <g id="Group_210" data-name="Group 210" transform="translate(-1004.012 -551.602)">
        <path
          id="Path_209"
          d="M2.073 263.545S-22.183 99.277 95.252 0c-1.644 3.826 41.882 144.648-51.709 272.783.057.297-41.47-9.238-41.47-9.238z"
          data-name="Path 209"
          transform="rotate(-21 2105.554 -2411.382)"
          fill="#b6d192"
        />
        <path
          id="Path_210"
          d="M0 275.058S55.792 20.566 77.993 0c0 .853-70.8 277.6-70.8 277.6z"
          data-name="Path 210"
          transform="rotate(-21 2074.21 -2465.817)"
          fill="#405036"
        />
        <path
          id="Path_211"
          d="M45.937 32.373L0 0"
          className="cls-3-leaf-error-8"
          data-name="Path 211"
          transform="rotate(-10.02 5056.677 -5694.243)"
        />
        <path
          id="Path_212"
          d="M33.346 88.947L0 0"
          className="cls-3-leaf-error-8"
          data-name="Path 212"
          transform="rotate(-21 2528.162 -2489.708)"
        />
        <path
          id="Path_213"
          d="M29.877 61L0 0"
          className="cls-3-leaf-error-8"
          data-name="Path 213"
          transform="rotate(-21 2264.11 -2534.45)"
        />
        <path
          id="Path_214"
          d="M26.867 60.649L0 0"
          className="cls-3-leaf-error-8"
          data-name="Path 214"
          transform="rotate(42 -221.408 1762.73)"
        />
        <path
          id="Path_215"
          d="M29.465 57.061L0 0"
          className="cls-3-leaf-error-8"
          data-name="Path 215"
          transform="rotate(63 13.16 1277.974)"
        />
      </g>
    </svg>
  );
};

export default Error8;
