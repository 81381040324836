import React from 'react';

const Mouth = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="65.907" height="28.254">
      <path
        d="M2.454 12.306a16.6 16.6 0 1 0 32.581-6.37A14.34 14.34 0 0 0 63.174.426"
        data-name="Path 26"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
    </svg>
  );
};

export default Mouth;
