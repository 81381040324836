import UnicaOneEot from './UnicaOne-Regular.eot';
import UnicaOneOtf from './UnicaOne-Regular.otf';
import UnicaOneSvg from './UnicaOne-Regular.svg';
import UnicaOneTtf from './UnicaOne-Regular.ttf';
import UnicaOneWoff from './UnicaOne-Regular.woff';
import UnicaOneWoff2 from './UnicaOne-Regular.woff2';

export default {
  UnicaOneEot,
  UnicaOneOtf,
  UnicaOneSvg,
  UnicaOneTtf,
  UnicaOneWoff,
  UnicaOneWoff2,
};
