import React from 'react';

import { keyframes } from 'styled-components';

import { Flex, Box } from '@rebass/grid';
import { compose, position, bottom, zIndex, height, display } from 'styled-system';

import Waves from '../assets/waves';
import Cat from '../assets/cat';

const moveArm = keyframes`
  50% {transform: translateY(20px)};
`;

const LookAtButton = ({ armBottom, aboutUs }) => {
  return (
    <Flex
      css={compose(
        position,
        bottom,
        zIndex,
      )}
      position="absolute"
      bottom={-91}
      zIndex={3}
      justifyContent="center"
    >
      <Waves.LookAtButton css={zIndex} zIndex={3} />
      <Cat.LeftArm
        css={`
          ${compose(
            bottom,
            display,
          )}
          animation: 1s ${moveArm} infinite;
          position: absolute;
        `}
        bottom={armBottom}
        display={[aboutUs ? 'none' : 'block', 'block']}
      />
      <Box
        css={`
          background: ${props => props.theme.colors.white};
          position: absolute;
          width: 100vw;
          max-width: 1920px;
          top: 25px;
          border-top: 5px solid ${props => props.theme.colors.black};
          ${compose(
            zIndex,
            height,
          )};
        `}
        zIndex={2}
        height={{ mobile: 100, laptop: 140 }}
      />
    </Flex>
  );
};

LookAtButton.defaultProps = {
  armBottom: [15, 30, 0, 40],
};

export default LookAtButton;
