import React from 'react';

import { compose, position, zIndex, right, top, bottom, left, display } from 'styled-system';
import { Flex } from '@rebass/grid';
import FlowerLeft from './BottomRight1';
import FlowerRight from './BottomRight2';

const BottomWaves = props => {
  return (
    <Flex
      {...props}
      css={compose(
        position,
        zIndex,
        right,
        top,
        bottom,
        left,
        display,
      )}
    >
      <FlowerLeft />
      <FlowerRight ml={-100} mt={65} />
    </Flex>
  );
};

BottomWaves.defaultProps = {
  position: 'absolute',
  right: 345,
  bottom: 15,
  zIndex: 3,
  display: { mobile: 'none', laptop: 'flex' },
};

export default BottomWaves;
