import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { Flex, Box } from '@rebass/grid';

import X from '../../../assets/x';
import { Langs, SocialMedia } from '../../';
import NavItem from './NavItem';

import { NAV_ITEMS, NAV_SLIDE_TIME } from '../../../constants';

const Nav = ({ isOpen, closeNav, currentPage, intl }) => {
  const isDisabledEnMenu = (label) => label === 'menu' && intl.locale === 'en';

  return (
    <Box
      css={`
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background: ${(props) => props.theme.colors.green};
        transform: ${isOpen ? 'translateX(0)' : 'translateX(-100vw)'};
        transition: all ${NAV_SLIDE_TIME};
        padding: 36px;
        overflow: scroll;
      `}
    >
      <Flex justifyContent="space-between">
        <Langs white />
        <button
          css={`
            margin: 0;
            padding: 0;
          `}
          onClick={closeNav}
        >
          <X />
        </button>
      </Flex>
      <Flex justifyContent="center" my={50}>
        <SocialMedia white />
      </Flex>
      <Flex as="nav" flexDirection="column" alignItems="center">
        {NAV_ITEMS.map(({ to, label }) => (
          <NavItem
            key={label}
            to={to}
            label={label}
            chosen={currentPage === label}
            disabled={isDisabledEnMenu(label)}
          />
        ))}
        <NavItem.Order />
      </Flex>
    </Box>
  );
};

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeNav: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
};

export default injectIntl(Nav);
