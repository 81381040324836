import React from 'react';
import PropTypes from 'prop-types';

const LeftArm = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="59.524" height="193.241">
      <g data-name="Group 125">
        <path d="M7.885 9.959l.7-9.82 1.08-.14 3.18 9.32z" data-name="Path 92" />
        <path d="M26.215 17.369l1.97-9.64h1.09l1.94 9.65z" data-name="Path 93" />
        <path d="M44.585 17.799l3.14-9.33 1.08.14.74 9.81z" data-name="Path 94" />
        <path
          d="M4.184 168.235c-.99-40.089-1.317-146.748-1.627-149.351s1.209-5.135 3.962-6.592a11.2 11.2 0 0 1 9.248-.422 8.44 8.44 0 0 1 3.248 2.231c1.091 1.309 1.616 10.862 1.654 12.218-.29-3.94 3.532-7.319 8.536-7.547s9.3 2.781 9.586 6.721c-.291-3.94 3.531-7.319 8.536-7.547s9.3 2.781 9.586 6.721 0 162.2 0 162.2-12.486 6.283-18.4 2.843-32-11.811-32-11.811z"
          data-name="Path 91"
          fill="#fff"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
    </svg>
  );
};

LeftArm.propTypes = {
  className: PropTypes.string,
};

LeftArm.defaultProps = {
  className: '',
};

export default LeftArm;
