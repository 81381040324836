import React from 'react';

const H205 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="195" height="205">
      <path
        d="M94.162 202.177l6.876-199.792"
        data-name="Path 216"
        fill="none"
        stroke="#405036"
        strokeWidth="5"
      />
    </svg>
  );
};

export default H205;
