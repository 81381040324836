import React from 'react';

const BottomRight1 = props => {
  return (
    <svg
      {...props}
      css={`
        .cls-3-leaf-bottom-right-1 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="145.073"
      height="273.974"
    >
      <defs />
      <g id="Group_234" data-name="Group 234" transform="translate(-1288.55 -1761.376)">
        <path
          id="Path_218"
          d="M0 9.544s24.086 128.288 82.715 239.592C81.078 245.641 124.407 117.03 31.24.006 31.292-.266 0 9.544 0 9.544z"
          data-name="Path 218"
          transform="rotate(167 602.033 1089.068)"
          fill="#4f810c"
        />
        <path
          id="Path_219"
          d="M0 2.377S52.864 240.1 73.9 259.315C73.9 258.518 6.816 0 6.816 0z"
          data-name="Path 219"
          transform="rotate(167 596.043 1088.005)"
          fill="#405036"
        />
        <path
          id="Path_221"
          d="M7.265 0L0 46.645"
          className="cls-3-leaf-bottom-right-1"
          data-name="Path 221"
          transform="rotate(-172 772.588 940.354)"
        />
        <path
          id="Path_223"
          d="M14.55 0L0 15.14"
          className="cls-3-leaf-bottom-right-1"
          data-name="Path 223"
          transform="rotate(167 573.372 1012.418)"
        />
        <path
          id="Path_224"
          d="M.109 0L0 22.147"
          className="cls-3-leaf-bottom-right-1"
          data-name="Path 224"
          transform="rotate(104 -51.72 1427.379)"
        />
        <path
          id="Path_222"
          d="M36.215 0L0 70.134"
          className="cls-3-leaf-bottom-right-1"
          data-name="Path 222"
          transform="rotate(83 -389.559 1734.843)"
        />
      </g>
    </svg>
  );
};

export default BottomRight1;
