import React from 'react';

const Error10 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-10 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="140.901"
      height="291.537"
    >
      <defs />
      <g id="Group_208" data-name="Group 208" transform="translate(-1248.099 -591.963)">
        <path
          id="Path_209"
          d="M2.073 263.545S-22.183 99.277 95.252 0c-1.644 3.826 41.882 144.648-51.709 272.783.057.297-41.47-9.238-41.47-9.238z"
          data-name="Path 209"
          transform="rotate(-8 5009.814 -8617.641)"
          fill="#4f810c"
        />
        <path
          id="Path_210"
          d="M0 275.058S55.792 20.566 77.993 0c0 .853-70.8 277.6-70.8 277.6z"
          data-name="Path 210"
          transform="rotate(-8 4944.551 -8768.203)"
          fill="#405036"
        />
        <path
          id="Path_211"
          d="M45.937 32.373L0 0"
          className="cls-3-leaf-error-10"
          data-name="Path 211"
          transform="rotate(3 -15048.695 24577.896)"
        />
        <path
          id="Path_212"
          d="M33.346 88.947L0 0"
          className="cls-3-leaf-error-10"
          data-name="Path 212"
          transform="rotate(-8 6129.924 -8695.962)"
        />
        <path
          id="Path_213"
          d="M29.877 61L0 0"
          className="cls-3-leaf-error-10"
          data-name="Path 213"
          transform="rotate(-8 5457.762 -8890.19)"
        />
        <path
          id="Path_214"
          d="M26.867 60.649L0 0"
          className="cls-3-leaf-error-10"
          data-name="Path 214"
          transform="rotate(54.97 59.727 1632.19)"
        />
        <path
          id="Path_215"
          d="M29.465 57.061L0 0"
          className="cls-3-leaf-error-10"
          data-name="Path 215"
          transform="rotate(76 212.742 1241.291)"
        />
      </g>
    </svg>
  );
};

export default Error10;
