import React from 'react';

const Error6 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-6 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="194.339"
      height="373.242"
    >
      <defs />
      <g id="Group_212" data-name="Group 212" transform="translate(-827.198 -647.461)">
        <path
          id="Path_218"
          d="M0 335.621S15.748 146.667 106.3 0c-2.1 4.9 53.581 185.054-66.153 348.982C40.215 349.363 0 335.621 0 335.621z"
          data-name="Path 218"
          transform="rotate(13 -2418.257 4301.752)"
          fill="#bbde8c"
        />
        <path
          id="Path_219"
          d="M0 351.892S71.376 26.311 99.78 0c0 1.091-90.58 355.147-90.58 355.147z"
          data-name="Path 219"
          transform="rotate(13 -2381.522 4359.554)"
          fill="#405036"
        />
        <path
          id="Path_221"
          d="M12.5 56.942L0 0"
          className="cls-3-leaf-error-6"
          data-name="Path 221"
          transform="rotate(-8 6728.268 -5626.726)"
        />
        <path
          id="Path_223"
          d="M30.883 29.493L0 0"
          className="cls-3-leaf-error-6"
          data-name="Path 223"
          transform="rotate(13 -3013.588 4388.315)"
        />
        <path
          id="Path_224"
          d="M34.372 77.591L0 0"
          className="cls-3-leaf-error-6"
          data-name="Path 224"
          transform="rotate(76 30.658 1018.706)"
        />
        <path
          id="Path_222"
          d="M37.7 73L0 0"
          className="cls-3-leaf-error-6"
          data-name="Path 222"
          transform="rotate(97 111.96 856.205)"
        />
      </g>
    </svg>
  );
};

export default Error6;
