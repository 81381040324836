import React from 'react';

const Pasta = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-1-cat-pasta {
          fill: none;
          stroke: #000;
          stroke-miterlimit: 10;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="20.473"
      height="85.248"
    >
      <defs />
      <g id="Group_133" data-name="Group 133" transform="translate(-963.426 -1832.314)">
        <path
          id="Line_1"
          d="M0 0l.23 84.13"
          className="cls-1-cat-pasta"
          data-name="Line 1"
          transform="translate(973.862 1833.423)"
        />
        <path
          id="Line_2"
          d="M.31 0L0 85.23"
          className="cls-1-cat-pasta"
          data-name="Line 2"
          transform="translate(981.09 1832.323)"
        />
        <path
          id="Line_3"
          d="M0 0v74.55"
          className="cls-1-cat-pasta"
          data-name="Line 3"
          transform="translate(965.926 1840.562)"
        />
      </g>
    </svg>
  );
};

export default Pasta;
