export default {
  'orient na orient': 'orient na orient',
  modalParagraph:
    'With the sense of taste, smell and sight, we take you to the oriental spaces. We want each of our dishes to be your culinary journey through Asia. We combine passion and experience in oriental cuisine so as to serve you the best version of it.',
  modalButton: 'see more',
  galleryHeader: 'VISIT US!',
  gallerySubHeader: 'SPACE IN THE HEART OF WROCŁAW WHERE WE ARE ORIENTED ON ASIAN FLAVOURS.',
  callToAction:
    "SZECHUAN, PAD THAI AND SHINKEN. DOESN'T THAT TELL YOU ANYTHING? CLICK BELOW AND FIND OUT A NEW ASIAN FLAVOURS!",
};
