import React, { useState, useEffect } from 'react';
import { compose, display, zIndex } from 'styled-system';
import Cursor from '../assets/cursor-mobile';
import cursorPointer from '../assets/cursor-pointer.png';

import { upAndDown } from '../styles/animations';

const MobileCursor = () => {
  const [isShown, setShown] = useState(false);

  const handleWindowScroll = () => {
    const scrollPosition = window.scrollY;
    const cursorShownH = 250;

    if (!isShown && scrollPosition > cursorShownH) setShown(true);
    if (isShown && scrollPosition < cursorShownH) setShown(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  });

  const handleOnClick = () => {
    const scrollInterval = 40;
    let i = window.scrollY;
    const interval = setInterval(() => {
      if (i > 0) {
        const newI = i >= scrollInterval ? i - scrollInterval : 0;
        window.scrollTo(0, newI);
        i = newI;
      }
      if (i <= 0) clearInterval(interval);
    }, 10);
  };

  return (
    <button
      onClick={handleOnClick}
      css={`
        ${compose(
          display,
          zIndex,
        )}
        width: 44px;
        height: 44px;
        position: fixed;
        bottom: calc(3.6rem - 15px);
        right: calc(3.6rem - 15px);
        opacity: ${isShown ? 1 : 0};
        animation: ${upAndDown('3px')} 1.5s infinite;
        transition: opacity 0.3s;
        cursor: url(${cursorPointer}), pointer;
        outline: none;
      `}
      zIndex={3}
      display={{ mobile: 'block', laptop: 'none' }}
    >
      <Cursor />
    </button>
  );
};

export default MobileCursor;
