import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { display, zIndex } from 'styled-system';
import { Box } from '@rebass/grid';
import { RemoveScroll } from 'react-remove-scroll';

import Hamburger from '../../../assets/hamburger';
import Nav from './Nav';

import { getIsNavOpen, setIsNavOpen } from './tools';
import { NAV_SLIDE_TIME } from '../../../constants';

const HamburgerButton = styled.button`
  position: absolute;
  top: calc(3.6rem - 15px);
  left: calc(3.6rem - 10px);
  margin: 0;
  padding: 0;
  min-width: 44px;
  min-height: 44px;
`;

const Mobile = ({ currentPage, theme: { breakpoints } }) => {
  const [isOpen, setOpen] = useState(getIsNavOpen());

  useEffect(() => {
    setIsNavOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  });

  const handleResizeWindow = event =>
    isOpen && event.target.innerWidth >= breakpoints.laptop && setOpen(false);

  const toggleNav = () => setOpen(wasOpen => !wasOpen);

  return (
    <>
      <Box
        css={`
          ${display}
          ${zIndex}
        position: absolute;
          top: 0;
          left: 0;
        `}
        display={{ laptop: 'none' }}
        zIndex={3}
      >
        <HamburgerButton onClick={toggleNav}>
          <Hamburger />
        </HamburgerButton>
        <RemoveScroll enabled={isOpen}>
          <Nav isOpen={isOpen} closeNav={toggleNav} currentPage={currentPage} />
        </RemoveScroll>
      </Box>
      <Box
        css={`
          ${display}
          position: absolute;
          top: ${currentPage === 'contact' ? '690px' : '640px'};
          width: 100vw;
          height: 100vh;
          background: ${props => props.theme.colors.white};
          z-index: ${props => props.theme.zIndices[4]};
          transform: ${isOpen ? 'translateX(0)' : 'translateX(-100vw)'};
          transition: all ${NAV_SLIDE_TIME};
        `}
        display={{ laptop: 'none' }}
      />
    </>
  );
};

Mobile.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

export default withTheme(Mobile);
