import React from 'react';
import PropTypes from 'prop-types';

import { space, fontSize } from 'styled-system';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';

import { Button, Link } from '..';

import { NAV_ITEMS } from '../../constants';

const navItems = [...NAV_ITEMS, { to: '/polityka-prywatnosci', label: 'privacyPolicy' }];

const NavItem = ({ to, label, chosen }) => (
  <Button.Text
    as={Link}
    to={to}
    chosen={chosen}
    css={`
      ${space}
      ${fontSize}
      color: ${({ theme }) => theme.colors.black};
      white-space: nowrap;

      &:hover {
        color: ${({ theme }) => theme.colors.grey};
      }
    `}
    mx={25}
    my="8px"
    fontSize={[16, 16, 16]}
  >
    <FormattedMessage id={`nav.${label}`} />
  </Button.Text>
);

const Nav = props => {
  return (
    <Flex flexWrap={['wrap', 'nowrap', 'nowrap']} justifyContent="center" {...props}>
      {navItems.map(({ to, label }) => (
        <NavItem key={label} to={to} label={label} chosen={props.currentPage === label} />
      ))}
    </Flex>
  );
};

Nav.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

export default Nav;
