import React from 'react';

const H270 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="257" height="270">
      <path
        d="M123.95 267.108l9.11-264.72"
        data-name="Path 216"
        fill="none"
        stroke="#405036"
        strokeWidth="5"
      />
    </svg>
  );
};

export default H270;
