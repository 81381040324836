import React from 'react';

import { withTheme } from 'styled-components';
import { color } from 'styled-system';
import { Flex, Box } from '@rebass/grid';

const Line = withTheme(({ theme: { colors: { black, white } } }) => (
  <Box
    css={`
      ${color}
      height: 1em;
      width: 1px;
      margin: 0 5px;

      @media (min-width: 350px) {
        margin: 0 10px;
      }
    `}
    bg={[black, white]}
  />
));

const Author = withTheme(({ label, mail, theme: { colors: { black, white } } }) => (
  <a href={`mailto: ${mail}`} css={color} color={[black, white]}>
    {label}
  </a>
));

const AllRights = ({ theme }) => {
  const { black, white, green } = theme.colors;
  return (
    <Flex
      css={color}
      justifyContent="center"
      width="100vw"
      pb={[50, 15]}
      pt={15}
      bg={['transparent', green]}
      color={[black, white]}
    >
      © {new Date().getFullYear()} all right reserved
      <Line />
      <Author label="design: agaskuta" mail="kontakt@agaskuta.pl" />
      <Line />
      <Author label="development: tomekskuta" mail="kontakt@tomekskuta.pl" />
    </Flex>
  );
};

export default withTheme(AllRights);
