import React from 'react';

const Error3 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-3 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="145.072"
      height="273.974"
    >
      <defs />
      <g id="Group_215" data-name="Group 215" transform="translate(-598.257 -749.416)">
        <path
          id="Path_218"
          d="M0 239.592S24.086 111.3 82.715 0c-1.637 3.495 41.692 132.106-51.475 249.13.052.27-31.24-9.538-31.24-9.538z"
          data-name="Path 218"
          transform="rotate(13 -3008.44 3251.401)"
          fill="#b4cd94"
        />
        <path
          id="Path_219"
          d="M0 256.938S52.864 19.211 73.9 0c0 .8-67.084 259.315-67.084 259.315z"
          data-name="Path 219"
          transform="rotate(13 -2954.442 3309.063)"
          fill="#405036"
        />
        <path
          id="Path_221"
          d="M7.265 46.645L0 0"
          className="cls-3-leaf-error-3"
          data-name="Path 221"
          transform="rotate(-8 6916.715 -3975.546)"
        />
        <path
          id="Path_223"
          d="M14.55 15.14L0 0"
          className="cls-3-leaf-error-3"
          data-name="Path 223"
          transform="rotate(13 -3362.572 3385.957)"
        />
        <path
          id="Path_224"
          d="M.109 22.147L0 0"
          className="cls-3-leaf-error-3"
          data-name="Path 224"
          transform="rotate(76 -149.224 871.637)"
        />
        <path
          id="Path_222"
          d="M36.215 70.134L0 0"
          className="cls-3-leaf-error-3"
          data-name="Path 222"
          transform="rotate(97 -40.242 772.567)"
        />
      </g>
    </svg>
  );
};

export default Error3;
