import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { Button, Link } from '../../';

import { setIsNavOpen } from './tools';
import { PYSZNE_LINK } from '../../../constants';

const NavItem = ({ label, to, chosen, disabled }) => {
  return (
    <Button.Text
      as={Link}
      to={to}
      white
      chosen={chosen}
      disabled={disabled}
      onClick={() => setIsNavOpen(false)}
      css={`
        margin: 2.5rem 0;
      `}
    >
      <FormattedMessage id={`nav.${label}`} />
    </Button.Text>
  );
};

const OrderNavItem = (props) => (
  <Button
    {...props}
    secondary
    as={Link}
    to={PYSZNE_LINK}
    external
    css={`
      border: 3px solid ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.green};
      color: ${(props) => props.theme.colors.white};
      margin: 2.5rem 0 3.6rem;

      &:hover {
        border: 3px solid ${(props) => props.theme.colors.white};
        background: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.green};
      }
    `}
  >
    <FormattedMessage id="nav.order" />
  </Button>
);

NavItem.Order = OrderNavItem;

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  chosen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

NavItem.defaultProps = {
  disabled: false,
};

export default NavItem;
