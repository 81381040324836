import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const BottomRight1 = props => {
  return (
    <FlowerConstructor
      {...props}
      LeafComponent={Leaf.BottomRight1}
      StalkComponent={Stalk.H180}
      leafStyles={`
        top: 25px;
        left: -45px;
        transform-origin: 88% 95%;
      `}
    />
  );
};

export default BottomRight1;
