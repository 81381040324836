import React from 'react';
import styled from 'styled-components';

const InstagramIcon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="25">
      <defs />
      <g id="Symbol_5_1" data-name="Symbol 5 – 1" transform="translate(-1626.506 -305.192)">
        <path
          id="Path_167"
          d="M33.242 23.88h-7.354a2.131 2.131 0 0 0-2.129 2.129v7.354a2.131 2.131 0 0 0 2.129 2.129h7.354a2.131 2.131 0 0 0 2.129-2.129v-7.354a2.131 2.131 0 0 0-2.129-2.129zm-3.677 9.628a3.822 3.822 0 1 1 3.823-3.823 3.827 3.827 0 0 1-3.823 3.823zm3.945-6.855a.9.9 0 1 1 .9-.9.906.906 0 0 1-.9.901z"
          className="cls-1"
          data-name="Path 167"
          transform="translate(1609.188 287.786)"
        />
        <path
          id="Path_168"
          d="M39.056 36.971a2.207 2.207 0 1 0 2.207 2.207 2.21 2.21 0 0 0-2.207-2.207z"
          className="cls-1"
          data-name="Path 168"
          transform="translate(1599.696 278.293)"
        />
        <path
          id="Path_169"
          d="M12.25 0A12.25 12.25 0 1 0 24.5 12.25 12.25 12.25 0 0 0 12.25 0zm7.369 15.934a3.722 3.722 0 0 1-3.719 3.718H8.6a3.722 3.722 0 0 1-3.718-3.718v-7.3A3.722 3.722 0 0 1 8.6 4.914h7.3a3.722 3.722 0 0 1 3.718 3.718z"
          className="cls-1"
          data-name="Path 169"
          transform="translate(1626.506 305.192)"
        />
      </g>
    </svg>
  );
};

export default styled(InstagramIcon)`
  path {
    fill: ${({ white, theme }) => (white ? theme.colors.white : theme.colors.green)};
  }
`;
