import React from 'react';

const BottomRight2 = props => {
  return (
    <svg
      {...props}
      css={`
        .cls-3-leaf-bottom-right-2 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="161.205"
      height="309.099"
    >
      <defs />
      <g id="Group_235" data-name="Group 235" transform="translate(-1434.115 -1818.052)">
        <path
          id="Path_218"
          d="M0 277.944S13.042 121.462 88.033 0c-1.743 4.054 44.372 153.252-54.785 289.008C33.3 289.324 0 277.944 0 277.944z"
          data-name="Path 218"
          transform="rotate(13 -7253.5 7490.682)"
          fill="#bbde8c"
        />
        <path
          id="Path_219"
          d="M0 291.419S59.11 21.789 82.632 0c0 .9-75.011 294.114-75.011 294.114z"
          data-name="Path 219"
          transform="rotate(13 -7223.08 7538.547)"
          fill="#405036"
        />
        <path
          id="Path_221"
          d="M10.349 47.156L0 0"
          className="cls-3-leaf-bottom-right-2"
          data-name="Path 221"
          transform="rotate(-8 15112.255 -9374.948)"
        />
        <path
          id="Path_223"
          d="M25.576 24.425L0 0"
          className="cls-3-leaf-bottom-right-2"
          data-name="Path 223"
          transform="rotate(13 -7746.526 7562.365)"
        />
        <path
          id="Path_224"
          d="M28.465 64.257L0 0"
          className="cls-3-leaf-bottom-right-2"
          data-name="Path 224"
          transform="rotate(76 -420.427 1963.953)"
        />
        <path
          id="Path_222"
          d="M31.218 60.455L0 0"
          className="cls-3-leaf-bottom-right-2"
          data-name="Path 222"
          transform="rotate(97 -99.792 1681.355)"
        />
      </g>
    </svg>
  );
};

export default BottomRight2;
