import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { Flex, Box } from '@rebass/grid';

const GridWidthBox = props => {
  const styles = css`
    max-width: 1280px;
    ${props.styles}
  `;

  const properties = {
    ...props,
    width: 1,
    px: { laptop: 40, desktop: 0 },
  };

  if (props.flex) return <Flex {...properties} css={styles} />;
  return <Box {...properties} css={styles} />;
};

GridWidthBox.propTypes = {
  flex: PropTypes.bool,
  styles: PropTypes.string,
};

export default GridWidthBox;
