export default {
  header1: 'NA CO MASZ DZISIAJ OCHOTĘ?',
  header2: 'ZUPA, MIĘSO, A MOŻE OWOCE MORZA?',
  header3: 'SPÓJRZ NIŻEJ I WYBIERZ SWOJE ULUBIONE ORIENTALNE DANIE!',
  'go to facebook': 'Zobacz co u nas:',
  see: 'zobacz',
  panko: 'panko',
  soup: 'zupa',
  meat: 'mięso',
  noodles: 'nudle',
  sea: 'morze',
  rice: 'RYŻ SMAŻONY',
  forKids: 'dla dzieci',
  sauce: 'sosy',
  drinks: 'napoje',
  'place order now': 'ZŁÓŻ ZAMÓWIENIE JUŻ TERAZ!',
  'want to order': 'CHCESZ ZAMÓWIĆ?',
  'want to get reservation': 'CHCESZ DOKONAĆ REZERWACJI?',
  call: 'ZADZWOŃ!',
};
