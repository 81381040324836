import React from 'react';

const Right1 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-right-1 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="133.835"
      height="220.189"
    >
      <defs />
      <g id="Group_199" data-name="Group 199" transform="translate(-1240.567 -3209)">
        <path
          id="Path_209"
          d="M1.564 198.905S-16.742 74.927 71.89 0c-1.241 2.888 31.61 109.17-39.026 205.877.036.223-31.3-6.972-31.3-6.972z"
          data-name="Path 209"
          transform="rotate(13 -13442.765 7295.055)"
          fill="#4f810c"
        />
        <path
          id="Path_210"
          d="M0 207.594S42.108 15.522 58.864 0c0 .644-53.435 209.514-53.435 209.514z"
          data-name="Path 210"
          transform="rotate(13 -13425.684 7369.436)"
          fill="#405036"
        />
        <path
          id="Path_211"
          d="M34.67 24.433L0 0"
          className="cls-3-leaf-right-1"
          data-name="Path 211"
          transform="rotate(24 -7277.704 4624.902)"
        />
        <path
          id="Path_212"
          d="M25.168 67.131L0 0"
          className="cls-3-leaf-right-1"
          data-name="Path 212"
          transform="rotate(13 -13961.607 7235.943)"
        />
        <path
          id="Path_213"
          d="M22.549 46.041L0 0"
          className="cls-3-leaf-right-1"
          data-name="Path 213"
          transform="rotate(13 -13670.702 7381.727)"
        />
        <path
          id="Path_214"
          d="M20.278 45.774L0 0"
          className="cls-3-leaf-right-1"
          data-name="Path 214"
          transform="rotate(76 -1405.572 2507.992)"
        />
        <path
          id="Path_215"
          d="M22.238 43.066L0 0"
          className="cls-3-leaf-right-1"
          data-name="Path 215"
          transform="rotate(97 -798.963 2259.745)"
        />
      </g>
    </svg>
  );
};

export default Right1;
