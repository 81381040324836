import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';

const LocalizedLink = injectIntl(({ to, intl: { locale }, ...props }) => (
  <Link {...props} to={`/${locale}${to}`} />
));

const ExternalLink = ({ to, children, ...props }) => (
  <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </a>
);

export default ({ external, ...props }) =>
  external ? <ExternalLink {...props} /> : <LocalizedLink {...props} />;
