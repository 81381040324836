import React from 'react';

const Error2 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-2 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="194.803"
      height="297.47"
    >
      <defs />
      <g id="Group_216" data-name="Group 216" transform="translate(-436.928 -594.602)">
        <path
          id="Path_209"
          d="M2.073 263.545S-22.183 99.277 95.252 0c-1.644 3.826 41.882 144.648-51.709 272.783.057.297-41.47-9.238-41.47-9.238z"
          data-name="Path 209"
          transform="rotate(-21 1938.015 -860.026)"
          fill="#4f810c"
        />
        <path
          id="Path_210"
          d="M0 275.058S55.792 20.566 77.993 0c0 .853-70.8 277.6-70.8 277.6z"
          data-name="Path 210"
          transform="rotate(-21 1906.672 -914.461)"
          fill="#405036"
        />
        <path
          id="Path_211"
          d="M45.937 32.373L0 0"
          className="cls-3-leaf-error-2"
          data-name="Path 211"
          transform="rotate(-10.02 5018.449 -2438.49)"
        />
        <path
          id="Path_212"
          d="M33.346 88.947L0 0"
          className="cls-3-leaf-error-2"
          data-name="Path 212"
          transform="rotate(-21 2360.624 -938.352)"
        />
        <path
          id="Path_213"
          d="M29.877 61L0 0"
          className="cls-3-leaf-error-2"
          data-name="Path 213"
          transform="rotate(-21 2096.57 -983.095)"
        />
        <path
          id="Path_214"
          d="M26.867 60.649L0 0"
          className="cls-3-leaf-error-2"
          data-name="Path 214"
          transform="rotate(42 -560.96 1045.578)"
        />
        <path
          id="Path_215"
          d="M29.465 57.061L0 0"
          className="cls-3-leaf-error-2"
          data-name="Path 215"
          transform="rotate(63 -305.468 836.77)"
        />
      </g>
    </svg>
  );
};

export default Error2;
