import React from 'react';

const H180 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="170" height="180">
      <path
        d="M89.74 179.906L84.04 2.34"
        data-name="Path 217"
        fill="none"
        stroke="#405036"
        strokeWidth="5"
      />
    </svg>
  );
};

export default H180;
