import React from 'react';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';

import { display } from 'styled-system';

const Part = props => (
  <Box
    mx={10}
    {...props}
    css={`
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.black};
      white-space: nowrap;
    `}
  />
);

const Line = styled(Box)`
  height: 1.6rem;
  width: 1px;
  background: ${({ theme }) => theme.colors.black};
  ${display}
`;

const Adress = props => {
  return (
    <Flex flexDirection={['column', 'row']} alignItems="center" {...props}>
      <Part>
        orientuj się - <FormattedMessage id="footer.asian food" />
      </Part>
      <Line display={['none', 'block']} />
      <Flex>
        <Part ml={[0, 10]}>św. mikołaja 14</Part>
        <Line />
        <Part mr={[0, 10]}>50-128 WROCŁAW</Part>
      </Flex>
    </Flex>
  );
};

export default Adress;
