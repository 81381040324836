import React from 'react';
import PropTypes from 'prop-types';

import { marginRight } from 'styled-system';

import FacebookIcon from '../assets/facebook-icon';
import InstagramIcon from '../assets/instagram-icon';
import Link from './Link';

import { FACEBOOK_LINK, INSTAGRAM_LINK } from '../constants';

const SocialMedia = ({ white, className }) => {
  return (
    <div className={className}>
      <Link
        external
        to={FACEBOOK_LINK}
        css={`
          ${marginRight}
        `}
        mr={[20, 20, 36]}
      >
        <FacebookIcon white={white} />
      </Link>
      <Link external to={INSTAGRAM_LINK}>
        <InstagramIcon white={white} />
      </Link>
    </div>
  );
};

SocialMedia.propTypes = {
  white: PropTypes.bool,
  className: PropTypes.string,
};

SocialMedia.defaultProp = {
  white: false,
  className: '',
};

export default SocialMedia;
