import { css } from 'styled-components';
import { compose, space, display, position, bottom } from 'styled-system';

export default css`
  ${compose(
    space,
    display,
    position,
    bottom,
  )}
  padding: 0;
  outline: none;
  font-size: 1.6rem;
  transition: all 0.2s;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    font-size: 2rem;
  }
`;
