import React from 'react';

import FlowerConstructor from './FlowerConstructor';
import { Leaf, Stalk } from '../../assets/flower';

const BottomRight2 = props => {
  return (
    <FlowerConstructor
      {...props}
      LeafComponent={Leaf.BottomRight2}
      StalkComponent={Stalk.H150}
      leafStyles={`
        top: 26px;
        left: 57px;
        transform-origin: 8% 94%;
      `}
    />
  );
};

export default BottomRight2;
