export default {
  header1: 'WHAT WOULD YOU LIKE TO EAT TODAY?',
  header2: 'SOUP, MEAT, MAYBE SEAFOOD?',
  header3: 'HAVE A LOOK BELOW AND CHOOSE YOUR FAVORITE ORIENTAL MEAL!',
  'go to facebook': 'Go to our Facebook page:',
  see: 'LOOK',
  panko: 'panko',
  soup: 'SOUP',
  meat: 'MEAT',
  noodles: 'NOODLES',
  sea: 'SEA',
  rice: 'FRIED RICE',
  forKids: 'FOR KIDS',
  sauce: 'SAUCES',
  drinks: 'DRINKS',
  'place order now': 'Order now!',
  'want to order': 'If you want to order,',
  'want to get reservation': 'If you want to make a reservation,',
  call: 'call us!',
};
