import React from 'react';

import { withTheme } from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';
import { Button, Link } from '..';

import {
  color,
  position,
  compose,
  display,
  zIndex,
  opacity,
  height,
  maxWidth,
} from 'styled-system';

import Waves from '../../assets/waves';

const Modal = ({ theme, header, text, buttonText, linkTo, aboutUs }) => (
  <Flex
    css={compose(
      color,
      zIndex,
      position,
      opacity,
      height,
    )}
    position="absolute"
    pt={[aboutUs ? 20 : '10%', 30, 40, 50]}
    pb={{ mobile: 40, desktop: aboutUs ? 50 : 100 }}
    px={['11vw', 36, 100]}
    bg={`${theme.colors.white}cc`}
    zIndex={1}
    flexDirection="column"
    alignItems="center"
    height={['100%', 'auto']}
    justifyContent={[aboutUs ? 'center' : 'flex-start', 'flex-start']}
  >
    <Flex
      css={position}
      justifyContent="center"
      position="relative"
      mb={{ mobile: 15, laptop: aboutUs ? 25 : 50 }}
    >
      <Waves.ModalH2
        css={`
          ${display}
          position: absolute;
          top: 0;
        `}
        display={['none', 'block']}
      />
      <h1
        css={`
          white-space: nowrap;
          margin-top: 0;
          margin-bottom: 0;
          line-height: 0.8;
        `}
      >
        <FormattedMessage id={header} />
      </h1>
    </Flex>
    <p
      css={`
        ${maxWidth}
        text-align: center;
        margin: 0;

        &,
        span {
          font-size: ${aboutUs ? '1.2rem' : '1.4rem'};

          @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            font-size: 1.4rem;
          }
        }
      `}
      maxWidth={['100%', aboutUs ? 650 : 400]}
    >
      <FormattedMessage id={text} />
    </p>
    <Button
      display={[aboutUs ? 'none' : 'flex', 'flex']}
      position={['absolute', 'static']}
      bottom={120}
      as={Link}
      to={linkTo}
      mt={[25, 25, 35]}
    >
      <FormattedMessage id={buttonText} />
    </Button>
  </Flex>
);

export default withTheme(Modal);
