import React from 'react';

const H230 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="221" height="230">
      <path
        d="M110.421 227.132l-.002-224.76"
        data-name="Path 229"
        fill="none"
        stroke="#405036"
        strokeWidth="5"
      />
    </svg>
  );
};

export default H230;
