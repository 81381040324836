import React from 'react';

const CursorMobile = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="21.408" height="32.75">
      <path
        d="M7.58 9.388a3.137 3.137 0 0 1 3.078-3.192 3.2 3.2 0 0 1 3.065 2.9l.013 2.894v-2.6a3.359 3.359 0 0 1 1.559-2.628 2.729 2.729 0 0 1 1.523-.416A3.137 3.137 0 0 1 19.9 9.39s.678 12.76 0 15.554c-.662 2.739-2.532 6.633-9.809 6.633-6.156 0-8.288-3.879-8.673-6.384-.488-3.328 0-15.8 0-15.8V4.219a3.137 3.137 0 0 1 3.08-3.044A3.137 3.137 0 0 1 7.58 4.219v7.49"
        data-name="Path 29"
        fill="#fff"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.35"
      />
    </svg>
  );
};

export default CursorMobile;
