import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { compose, position, left, top, right, bottom, zIndex, display } from 'styled-system';

const FlowerConstructor = ({
  LeafComponent,
  StalkComponent,
  leafStyles,
  stalkStyles,
  hoverRotate,
  ...props
}) => {
  const Leaf = styled(LeafComponent)`
    ${leafStyles}
    ${zIndex}
    position: relative;
    transform: rotate(0);
    transition: all 0.2s ease-out;

    &:hover {
      transform: rotate(${hoverRotate});
    }
  `;
  const Stalk = styled(StalkComponent)`
    ${stalkStyles}
  `;

  return (
    <Flex
      css={compose(
        position,
        left,
        top,
        right,
        bottom,
        display,
      )}
      flexDirection="column"
      {...props}
    >
      <Leaf zIndex={props.zIndex} />
      <Stalk />
    </Flex>
  );
};

FlowerConstructor.propTypes = {
  LeafComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]).isRequired,
  StalkComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func])
    .isRequired,
  leafStyles: PropTypes.string,
  stalkStyles: PropTypes.string,
  hoverRotate: PropTypes.string,
};

FlowerConstructor.defaultProps = {
  leafStyles: '',
  stalkStyles: '',
  hoverRotate: '15deg',
};

export default FlowerConstructor;
