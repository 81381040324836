import React from 'react';
import LeftArm from './left-arm';

const RightArm = ({ className }) => {
  return (
    <LeftArm
      className={className}
      css={`
        transform: rotateY(180deg);
      `}
    />
  );
};

export default RightArm;
