import Left1 from './left1';
import Left2 from './left2';
import Right1 from './right1';
import BottomRight1 from './bottom-right1';
import BottomRight2 from './bottom-right2';
import Error01 from './404-01';
import Error02 from './404-02';
import Error03 from './404-03';
import Error04 from './404-04';
import Error05 from './404-05';
import Error06 from './404-06';
import Error07 from './404-07';
import Error08 from './404-08';
import Error09 from './404-09';
import Error10 from './404-10';
import Error11 from './404-11';
import Error12 from './404-12';

export default {
  Left1,
  Left2,
  Right1,
  BottomRight1,
  BottomRight2,
  Error01,
  Error02,
  Error03,
  Error04,
  Error05,
  Error06,
  Error07,
  Error08,
  Error09,
  Error10,
  Error11,
  Error12,
};
