import React from 'react';
import styled from 'styled-components';

const FacebookIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="25" height="25">
      <g data-name="Symbol 4 – 1">
        <path
          d="M12.25 0A12.252 12.252 0 1 0 24.5 12.252 12.252 12.252 0 0 0 12.25 0zm3.167 12.25H13.41v7.351h-2.757v-7.349H8.815V9.719h1.837V8.227c0-2.067.56-3.325 2.994-3.325h2.027v2.533H14.4c-.948 0-.994.354-.994 1.014v1.268h2.278z"
          data-name="Path 166"
        />
      </g>
    </svg>
  );
};

export default styled(FacebookIcon)`
  path {
    fill: ${({ white, theme }) => (white ? theme.colors.white : theme.colors.green)};
  }
`;
