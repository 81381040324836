import React from 'react';

const Error5 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-5 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="150.936"
      height="358.442"
    >
      <defs />
      <g id="Group_213" data-name="Group 213" transform="translate(-800.948 -536)">
        <path
          id="Path_218"
          d="M0 332.145S15.585 145.148 105.2 0c-2.082 4.845 53.026 183.138-65.467 345.368C39.8 345.745 0 332.145 0 332.145z"
          data-name="Path 218"
          transform="rotate(2 -15209.846 23646.858)"
          fill="#82c030"
        />
        <path
          id="Path_219"
          d="M0 348.248S70.637 26.038 98.746 0c0 1.08-89.639 351.469-89.639 351.469z"
          data-name="Path 219"
          transform="rotate(2 -14939.578 23993.578)"
          fill="#405036"
        />
        <path
          id="Path_221"
          d="M12.367 56.352L0 0"
          className="cls-3-leaf-error-5"
          data-name="Path 221"
          transform="rotate(-19.01 2719.631 -2009.05)"
        />
        <path
          id="Path_223"
          d="M30.563 29.188L0 0"
          className="cls-3-leaf-error-5"
          data-name="Path 223"
          transform="rotate(2 -18960.586 24566.236)"
        />
        <path
          id="Path_224"
          d="M34.016 76.787L0 0"
          className="cls-3-leaf-error-5"
          data-name="Path 224"
          transform="rotate(65 -11.694 1039.797)"
        />
        <path
          id="Path_222"
          d="M37.305 72.245L0 0"
          className="cls-3-leaf-error-5"
          data-name="Path 222"
          transform="rotate(85.99 73.23 854.156)"
        />
      </g>
    </svg>
  );
};

export default Error5;
