export default time => {
  const timeArray = time.split(':').map(elem => Number(elem));
  const hour = timeArray[0];
  const minute = timeArray[1];

  const getTwoDigits = time => (time < 10 ? `0${time}` : time);
  const isPM = hour > 12;
  const timeOfDay = isPM ? 'pm' : 'am';
  const formattedHour = isPM ? hour - 12 : hour;

  return `${getTwoDigits(formattedHour)}:${getTwoDigits(minute)} ${timeOfDay}`;
};
