import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { display, height } from 'styled-system';

import Link from '../Link';
import Langs from '../Langs';
import SocialMedia from '../SocialMedia';
import Nav from '../Nav';
import GridWidthBox from '../GridWidthBox';

import Logo from '../../assets/logo';

const LogoLink = styled(Link)`
  position: absolute;
  left: 50%;
  transform: translateX(-58px);

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    transform: translateX(-90px);
  }
`;

const Header = ({ currentPage }) => (
  <GridWidthBox as="header" pt={{ mobile: 30, laptop: 45 }} pb={{ mobile: 0, laptop: 60 }}>
    <Flex
      css={`
        ${height}
      `}
      justifyContent="space-between"
      height={[110, 110, 168]}
    >
      <Nav.Mobile currentPage={currentPage} />
      <Langs
        css={`
          ${display}
        `}
        display={['none', 'none', 'block']}
      />
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      <SocialMedia
        css={`
          ${display}
        `}
        display={['none', 'none', 'block']}
      />
    </Flex>
    <Nav currentPage={currentPage} />
  </GridWidthBox>
);

Header.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

Header.defaultProps = {};

export default Header;
