import React from 'react';
import PropTypes from 'prop-types';

const X = ({ className }) => (
  <svg
    className={className}
    css={`
      path {
        fill: ${props => props.theme.colors.white};
      }
    `}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="15.999"
  >
    <defs>
      <clipPath id="clip">
        <use xlinkHref="#fill" />
      </clipPath>
      <clipPath id="clip-2">
        <use xlinkHref="#fill-2" />
      </clipPath>
    </defs>
    <g id="X" transform="translate(-321.868 -31.368)">
      <g
        id="Rectangle_39"
        className="cls-1"
        data-name="Rectangle 39"
        transform="rotate(45 124.113 406.733)"
      >
        <path id="fill" d="M0 0h19.675v2.951H0z" className="cls-2" />
        <path
          d="M0 1.451h19.675M18.175 0v2.951m1.5-1.451H0m1.5 1.451V0"
          clipPath="url(#clip)"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_40"
        className="cls-1"
        data-name="Rectangle 40"
        transform="rotate(135 162.005 86.702)"
      >
        <path id="fill-2" d="M0 0h19.675v2.951H0z" className="cls-2" />
        <path
          d="M0 1.451h19.675M18.175 0v2.951m1.5-1.451H0m1.5 1.451V0"
          clipPath="url(#clip-2)"
          fill="none"
        />
      </g>
    </g>
  </svg>
);

X.propTypes = {
  className: PropTypes.string,
};

X.defaultProps = {
  className: '',
};

export default X;
