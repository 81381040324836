import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@rebass/grid';

import Carousel from './Carousel';
import Modal from './Modal';
import LookAtButton from '../LookAtButton';

import { position } from 'styled-system';

import { sortImages } from '../../helpers';

const SubHeader = ({ modalHeader, modalText, buttonText, linkTo, aboutUs, armBottom, images }) => {
  return (
    <Flex
      as="section"
      css={position}
      width={1}
      position="relative"
      justifyContent="center"
      alignItems="center"
      mb={{ mobile: 100, laptop: 140 }}
      mt={{ mobile: 20, laptop: 0 }}
    >
      <Carousel images={sortImages(images)} />
      <Modal
        header={modalHeader}
        text={modalText}
        buttonText={buttonText}
        linkTo={linkTo}
        aboutUs={aboutUs}
      />
      <LookAtButton aboutUs={aboutUs} armBottom={armBottom} />
    </Flex>
  );
};

SubHeader.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalHeader: PropTypes.string.isRequired,
  modalText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  aboutUs: PropTypes.bool,
  armBottom: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default SubHeader;
