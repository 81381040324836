import React from 'react';
import PropTypes from 'prop-types';

import { space } from 'styled-system';
import { Flex } from '@rebass/grid';
import { GridWidthBox, SocialMedia } from '../';
import Nav from './Nav';
import Adress from './Adress';
import AllRights from './AllRights';

const Footer = ({ currentPage }) => {
  return (
    <footer
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <GridWidthBox px={0}>
        <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          my={{ mobile: 15, laptop: 25 }}
          mx={{ mobile: 36, laptop: 0 }}
          px={{ laptop: 40, desktop: 0 }}
        >
          <Nav currentPage={currentPage} mx={[0, 0, 25]} my={15} />
          <Adress mx={[0, 25]} my={15} />
          <SocialMedia
            css={`
              ${space}
              display: flex;
              flex-wrap: nowrap;
            `}
            mx={[0, 25]}
            my={15}
          />
        </Flex>
      </GridWidthBox>
      <AllRights />
    </footer>
  );
};

Footer.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

export default Footer;
