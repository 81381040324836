const breakpoints = [690, 995, 1440];

breakpoints.mobile = 0;
breakpoints.tablet = breakpoints[0];
breakpoints.laptop = breakpoints[1];
breakpoints.desktop = breakpoints[2];

export default {
  colors: {
    green: '#58851c',
    pine: '#405036',
    black: '#000000',
    darkGrey: '#000000cc',
    grey: '#707070',
    white: '#ffffff',
  },
  breakpoints,
  zIndices: [100, 200, 300, 400, 500, 600],
};
