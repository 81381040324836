import React from 'react';

const HomeGallery = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-1-waves-home-gallery {
          fill: none;
          stroke: #405036;
          stroke-miterlimit: 10;
          stroke-width: 5px;
          opacity: 0.389;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="481"
      height="84"
    >
      <defs />
      <g id="fala_lewa" data-name="fala lewa" transform="translate(-59.404 -1506.5)">
        <path
          id="Path_231"
          d="M427.94 49.23c-51.33-1.74-54.26-18.6-111.16-18.6-60 0-60 18.74-120 18.74s-60-18.74-120-18.74c-30 0-45 4.68-60 9.37"
          className="cls-1-waves-home-gallery"
          data-name="Path 231"
          transform="translate(59.4 1518.5)"
        />
        <path
          id="Path_230"
          d="M427.94 63.29c-51.33-1.74-54.26-18.6-111.16-18.6-60 0-60 18.75-120 18.75s-60-18.75-120-18.75c-30 0-45 4.68-60 9.37"
          className="cls-1-waves-home-gallery"
          data-name="Path 230"
          transform="translate(59.4 1524.5)"
        />
        <path
          id="Path_232"
          d="M414.07 34.24c-38.56-4-46-17.68-97.29-17.68-60 0-60 18.75-120 18.75s-60-18.75-120-18.75"
          className="cls-1-waves-home-gallery"
          data-name="Path 232"
          transform="translate(59.4 1512.5)"
        />
        <path
          id="Path_233"
          d="M480.48 16.56c-11 2.69-24.15 4.69-43.72 4.69-60 0-60-18.75-120-18.75s-60 18.75-120 18.75-60-18.75-120-18.75C36.43 2.5 23.24 11 .6 16.56"
          className="cls-1-waves-home-gallery"
          data-name="Path 233"
          transform="translate(59.4 1506.5)"
        />
      </g>
    </svg>
  );
};

export default HomeGallery;
