import React from 'react';

const Left2 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-left-2 {
          stroke: #454b42;
          fill: none;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="129.023"
      height="248.499"
    >
      <defs />
      <g id="Group_197" data-name="Group 197" transform="translate(-399.16 -3088.992)">
        <path
          id="Path_234"
          d="M0 223.628S19.936 106.119 79.066 0C77.671 3.247 114.6 122.739 35.19 231.466c.044.253-35.19-7.838-35.19-7.838z"
          data-name="Path 234"
          transform="rotate(11 -15843.388 3848.952)"
          fill="#bbde8c"
        />
        <path
          id="Path_235"
          d="M0 233.4S47.341 17.451 66.18 0C66.18.724 6.1 235.556 6.1 235.556z"
          data-name="Path 235"
          transform="rotate(11 -15818.104 3938.18)"
          fill="#405036"
          stroke="#454b42"
        />
        <path
          id="Path_240"
          d="M22.089 15.6L0 0"
          className="cls-3-leaf-left-2"
          data-name="Path 240"
          transform="rotate(22 -8165.706 2712.403)"
        />
        <path
          id="Path_236"
          d="M18.465 41.915L0 0"
          className="cls-3-leaf-left-2"
          data-name="Path 236"
          transform="rotate(11 -16577.105 3847.052)"
        />
        <path
          id="Path_238"
          d="M18.251 36.636L0 0"
          className="cls-3-leaf-left-2"
          data-name="Path 238"
          transform="rotate(28 -6153.92 2525.971)"
        />
        <path
          id="Path_239"
          d="M16.008 44.366L0 0"
          className="cls-3-leaf-left-2"
          data-name="Path 239"
          transform="rotate(74 -1837.16 1924.392)"
        />
        <path
          id="Path_237"
          d="M25 48.418L0 0"
          className="cls-3-leaf-left-2"
          data-name="Path 237"
          transform="rotate(95 -1225.672 1842.138)"
        />
      </g>
    </svg>
  );
};

export default Left2;
