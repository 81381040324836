import React from 'react';

const Error12 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-12 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="162.323"
      height="366.552"
    >
      <defs />
      <g id="Group_240" data-name="Group 240" transform="translate(-1516.209 -646.084)">
        <g id="Group_206" data-name="Group 206">
          <path
            id="Path_218"
            d="M0 335.621S15.748 146.667 106.3 0c-2.1 4.9 53.581 185.054-66.153 348.982C40.215 349.363 0 335.621 0 335.621z"
            data-name="Path 218"
            transform="rotate(5 -6724.705 18039.139)"
            fill="#bbde8c"
          />
          <path
            id="Path_219"
            d="M0 351.892S71.376 26.311 99.78 0c0 1.091-90.58 355.147-90.58 355.147z"
            data-name="Path 219"
            transform="rotate(5 -6619.134 18182.127)"
            fill="#405036"
          />
          <path
            id="Path_221"
            d="M12.5 56.942L0 0"
            className="cls-3-leaf-error-12"
            data-name="Path 221"
            transform="rotate(-16 3919.615 -4969.765)"
          />
          <path
            id="Path_223"
            d="M30.883 29.493L0 0"
            className="cls-3-leaf-error-12"
            data-name="Path 223"
            transform="rotate(5 -8250.308 18371.02)"
          />
          <path
            id="Path_224"
            d="M34.372 77.591L0 0"
            className="cls-3-leaf-error-12"
            data-name="Path 224"
            transform="rotate(68 292.723 1597.088)"
          />
          <path
            id="Path_222"
            d="M37.7 73L0 0"
            className="cls-3-leaf-error-12"
            data-name="Path 222"
            transform="rotate(89.03 394.95 1254.597)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Error12;
