import { createGlobalStyle } from 'styled-components';

import cursor from '../assets/cursor.png';
import cursorPointer from '../assets/cursor-pointer.png';
import font from '../assets/fonts';

import slickStyles from 'slick-carousel/slick/slick.css';
import slickTheme from 'slick-carousel/slick/slick-theme.css';

export default createGlobalStyle`
  ${slickStyles}
  ${slickTheme}

  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
  
  @font-face {
    font-family: 'UnicaOne-Regular';
    src: url(${font.UnicaOneEot});
    src: url(${font.UnicaOneEot}) format('embedded-opentype'),
      url(${font.UnicaOneOtf}) format("opentype"),
      url(${font.UnicaOneSvg}) format('svg'),
      url(${font.UnicaOneTtf}) format('truetype'),
      url(${font.UnicaOneWoff}) format('woff'),
      url(${font.UnicaOneWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  * {
    cursor: url(${cursor}), auto;
    font-family: 'UnicaOne-Regular', sans-serif;
    text-transform: uppercase;
  }

  a, button, a *, button * {
    cursor: url(${cursorPointer}), pointer;
    text-decoration: none;
    border: 0;
    background: none;
  }

  a:active, button:active, a *:active, button *:active {
    cursor: url(${cursor}), pointer;
  }

  html {
   font-size: 10px;
  }

  h1, h2, h3 {
    color: ${props => props.theme.colors.black};
    text-align: center;
    font-weight: normal;
  }

  h1 {
    font-size: 3rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 2.2rem;
    line-height: 1.5;
  }

  h3 {
    font-size: 2rem;
    line-height: 1.5;
  }

  p, p span {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    line-height: 1.75;
    color: ${props => props.theme.colors.darkGrey};
    text-transform: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    h1 {
      font-size: 5rem;
      line-height: 1.18;
    }
  
    h2 {
      font-size: 4rem;
      line-height: 1.5;
    }
  
    h3 {
      font-size: 2.2rem;
      line-height: 1.5;
    }

    p, p span {
      font-size: 1.4rem;
      line-height: 1.71;
    }
  }
`;
