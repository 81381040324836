import React from 'react';

const H220 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="212" height="220">
      <path
        d="M105.955 217.83L105.95 2.372"
        data-name="Path 229"
        fill="none"
        stroke="#405036"
        strokeWidth="5"
      />
    </svg>
  );
};

export default H220;
