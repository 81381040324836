export default {
  'orient na orient': 'orient na orient',
  modalParagraph:
    'Zmysłem smaku, węchu i wzroku przenosimy Cię w orientalne przestrzenie. Chcemy, aby każde z naszych dań, było dla Ciebie kulinarną podróżą po Azji. Łączymy pasję i doświadczenie do kuchni orientalnej tak, aby w efekcie zaserwować Ci jej najlepszą wersję.',
  modalButton: 'zobacz więcej',
  galleryHeader: 'WPADNIJ DO NAS!',
  gallerySubHeader: 'PRZESTRZEŃ W SERCU WROCŁAWIA W KTÓREJ ORIENTUJEMY SIĘ NA AZJATYCKIE SMAKI.',
  callToAction:
    'SZECHUAN, PAD THAI I SHINKEN. NIC CI TO NIE MÓWI? KLIKNIJ PONIŻEJ I ZORIENTUJ SIĘ NA NOWE, AZJATYCKIE SMAKI!',
};
