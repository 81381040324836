import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import styles from './styles';

const TextButton = styled.button`
  ${styles}
  font-size: 2rem;
  border: 0;
  background: none;
  margin: 1rem 2.5rem;
  color: ${({ theme, white, chosen }) =>
    chosen && !white ? theme.colors.black : white ? theme.colors.white : theme.colors.grey};
  opacity: ${({ chosen, white }) => (chosen || !white ? 1 : 0.6)};

  ${({ disabled, white }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: ${white ? 0.2 : 0.5};
    `}

  &:hover {
    color: ${({ theme, white }) => (white ? theme.colors.white : theme.colors.black)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    margin: 1rem 3rem;
    font-size: ${({ small }) => (small ? '1.6rem' : '2rem')};
  }
`;

TextButton.propTypes = {
  white: PropTypes.bool,
  chosen: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextButton.defaultProps = {
  white: false,
  chosen: false,
  small: false,
  disabled: false,
};

export default TextButton;
