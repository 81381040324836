import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider, addLocaleData } from 'react-intl';
import styled, { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';

import { Flex } from '@rebass/grid';

import PageContext from './PageContext';
import { GlobalStyles, theme } from '../styles';

import plData from 'react-intl/locale-data/pl';
import enData from 'react-intl/locale-data/en';
import { translations, languages } from '../i18n';

import { Header, SEO, Footer, MobileCursor } from '../components';

addLocaleData([...plData, ...enData]);

const Layout = styled(Flex)`
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const withLayout = customProps => PageComponent => props => {
  const { locale } = props.pageContext;
  const { localeKey, keywords } = customProps;

  const pageContextValue = { custom: customProps, page: props.pageContext };

  const defaultLocale = languages.find(language => language.default).locale;
  const pageLocale = locale || defaultLocale;

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <IntlProvider locale={pageLocale} messages={translations[pageLocale]}>
          <PageContext.Provider value={pageContextValue}>
            <ThemeProvider theme={theme}>
              <>
                <Normalize />
                <GlobalStyles />
                <SEO lang={pageLocale} keywords={keywords} />
                <Layout id="top">
                  <Header currentPage={localeKey} />
                  <main
                    css={`
                      width: 100vw;
                    `}
                  >
                    <PageComponent {...props} />
                  </main>
                  <Footer currentPage={localeKey} />
                  <MobileCursor />
                </Layout>
              </>
            </ThemeProvider>
          </PageContext.Provider>
        </IntlProvider>
      )}
    />
  );
};

withLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withLayout;
