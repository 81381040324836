import React from 'react';

const Nose = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16.481" height="12.066">
      <path
        d="M16.324 2.169c-.88-4.49-17.17-1.31-16.29 3.19a8.3 8.3 0 1 0 16.29-3.19"
        data-name="Path 7"
      />
    </svg>
  );
};

export default Nose;
