import React from 'react';
import PropTypes from 'prop-types';

const SubHeaderWaves = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="350" height="119">
      <g data-name="Group 232" fill="#fff">
        <path d="M5.296 25.253h345v94h-345z" data-name="Rectangle 1108" />
        <path
          d="M350.383 27c-17.388 0-17.388-24.5-34.777-24.5S298.218 27 280.83 27 263.442 2.5 246.053 2.5 228.665 27 211.276 27 193.889 2.5 176.5 2.5 159.112 27 141.724 27 124.335 2.5 106.947 2.5 89.559 27 72.17 27 54.782 2.5 37.394 2.5c-11.711 0-15.534 11.121-22.1 18.374A18.275 18.275 0 0 1 .331 27"
          data-name="Path 115"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
      </g>
    </svg>
  );
};

SubHeaderWaves.propTypes = {
  className: PropTypes.string,
};

SubHeaderWaves.defaultProps = {
  className: '',
};

export default SubHeaderWaves;
