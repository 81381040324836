import { keyframes } from 'styled-components';

export const scratch = left => keyframes`
  0% {
    transform: rotate(${left ? '-' : ''}180deg) ${left ? 'rotateY(180deg)' : ''};
  }
  7% {
    transform: rotate(${left ? '-' : ''}540deg) ${left ? 'rotateY(180deg)' : ''};
  }
  14% {
    transform: rotate(${left ? '-' : ''}900deg) ${left ? 'rotateY(180deg)' : ''};
  }
  60% {
    transform: rotate(${left ? '-' : ''}900deg) ${left ? 'rotateY(180deg)' : ''};
  }
  67% {
    transform: rotate(${left ? '-' : ''}1260deg) ${left ? 'rotateY(180deg)' : ''};
  }
  100% {
    transform: rotate(${left ? '-' : ''}1260deg) ${left ? 'rotateY(180deg)' : ''};
  }
`;

export const upAndDown = range => keyframes`
  50% {
    transform: translateY(${range});
  }
`;

export const moveArm = right => keyframes`
  0%, 100% {
    transform: translateY(${right ? '-15px' : 0}) ${right && 'rotateY(180deg)'};
  }
  50% {
    transform: translateY(${right ? 0 : '-15px'}) ${right && 'rotateY(180deg)'};
  }
`;

export const moveArmMobile = keyframes`
  0%, 60%, 80%, 100% {
    transform: translateY(0) rotateY(180deg);
  }
  70%, 90% {
    transform: translateY(-15px) rotateY(180deg);
  }
`;

export const movePin = keyframes`
  0%, 100% {
    transform: translateY(-3px);
  }

  50% {
    transform: translateY(0);
  }
`;
