import cat from './cat';
import eyes from './eyes';
import nose from './nose';
import mouth from './mouth';
import pasta from './pasta';
import leftArm from './left-arm';
import rightArm from './right-arm';

cat.Eyes = eyes;
cat.Nose = nose;
cat.Mouth = mouth;
cat.Pasta = pasta;
cat.LeftArm = leftArm;
cat.RightArm = rightArm;

export default cat;
