import React from 'react';
import PropTypes from 'prop-types';

import { height } from 'styled-system';

import { Flex, Box } from '@rebass/grid';
import Img from 'gatsby-image';
import Slider from 'react-slick';

const Carousel = ({ images }) => {
  const options = {
    arrows: false,
    dots: false,
    autoplay: true,
    draggable: false,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    autoplaySpeed: 4000,
    speed: 500,
  };

  return (
    <Box css={height} width="100vw" height="100%">
      <Slider {...options}>
        {images.map((image, index) => (
          <Box
            key={index}
            css={`
              height: 100%;
              overflow: hidden;
            `}
          >
            <Flex width="100%" justifyContent="center">
              <Box
                css={`
                  width: 100vw;
                  height: 100%;
                  min-width: 1280px;
                  max-width: 1920px;
                `}
              >
                <Img
                  css={`
                    height: 100%;
                  `}
                  fluid={image.node.childImageSharp.fluid}
                />
              </Box>
            </Flex>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

Carousel.propTyeps = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Carousel;
