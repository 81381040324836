import React from 'react';

const ContactLeft = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-1-waves-contact-left {
          fill: none;
          stroke: #405036;
          stroke-miterlimit: 10;
          stroke-width: 5px;
          opacity: 0.389;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="378.922"
      height="83.81"
    >
      <defs />
      <g id="Group_155" data-name="Group 155" transform="translate(-172.674 -4246.63)">
        <path
          id="Path_231"
          d="M127.94 30.63c60 0 60 18.74 120 18.74s60-18.74 120-18.74c30 0 45 4.68 60 9.37"
          className="cls-1-waves-contact-left"
          data-name="Path 231"
          transform="translate(106.88 4218.5)"
        />
        <path
          id="Path_230"
          d="M127.94 44.69c60 0 60 18.75 120 18.75s60-18.75 120-18.75c30 0 45 4.68 60 9.37"
          className="cls-1-waves-contact-left"
          data-name="Path 230"
          transform="translate(106.88 4264.5)"
        />
        <path
          id="Path_232"
          d="M174.07 16.56c60 0 60 18.75 120 18.75s60-18.75 120-18.75"
          className="cls-1-waves-contact-left"
          data-name="Path 232"
          transform="translate(60.75 4272.5)"
        />
        <path
          id="Path_233"
          d="M102.9 12.319C118.2 7.551 133.18 2.5 164.32 2.5c60 0 60 18.75 120 18.75s60-18.75 120-18.75c40.33 0 53.52 8.5 76.16 14.06"
          className="cls-1-waves-contact-left"
          data-name="Path 233"
          transform="translate(70.52 4266.5)"
        />
      </g>
    </svg>
  );
};

export default ContactLeft;
