import React from 'react';
import PropTypes from 'prop-types';

const Left1 = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="247"
      css={`
        .cls-3-leaf-left-1 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
    >
      <defs />
      <g id="Group_198" data-name="Group 198" transform="translate(-275.457 -3208.592)">
        <path
          id="Path_234"
          d="M1.759 7.844s19.936 117.509 79.066 223.628C79.43 228.225 116.363 108.733 36.948.006c.045-.253-35.189 7.838-35.189 7.838z"
          data-name="Path 234"
          transform="rotate(169 37.55 1738.887)"
          fill="#4f810c"
        />
        <path
          id="Path_235"
          d="M0 2.159S47.341 218.1 66.18 235.556C66.18 234.832 6.1 0 6.1 0z"
          data-name="Path 235"
          transform="rotate(169 28.277 1738.579)"
          fill="#405036"
        />
        <path
          id="Path_240"
          d="M22.089 0L0 15.6"
          className="cls-3-leaf-left-1"
          data-name="Path 240"
          transform="rotate(157.97 -135.748 1732.223)"
        />
        <path
          id="Path_236"
          d="M18.465 0L0 41.915"
          className="cls-3-leaf-left-1"
          data-name="Path 236"
          transform="rotate(169 27.729 1714.842)"
        />
        <path
          id="Path_238"
          d="M18.251 0L0 36.636"
          className="cls-3-leaf-left-1"
          data-name="Path 238"
          transform="rotate(152 -236.899 1710.551)"
        />
        <path
          id="Path_239"
          d="M16.008 0L0 44.366"
          className="cls-3-leaf-left-1"
          data-name="Path 239"
          transform="rotate(106 -1076.057 1768.375)"
        />
        <path
          id="Path_237"
          d="M25 0L0 48.419"
          className="cls-3-leaf-left-1"
          data-name="Path 237"
          transform="rotate(85 -1646.118 1859.135)"
        />
      </g>
    </svg>
  );
};

Left1.propTypes = {
  className: PropTypes.string,
};

Left1.defaultProps = {
  className: '',
};

export default Left1;
