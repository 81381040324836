import React from 'react';

const Error11 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-11 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="145.072"
      height="273.974"
    >
      <defs />
      <g id="Group_207" data-name="Group 207" transform="translate(-1285 -700.416)">
        <path
          id="Path_218"
          d="M0 9.544s24.086 128.288 82.715 239.592C81.078 245.641 124.407 117.03 31.24.006 31.292-.266 0 9.544 0 9.544z"
          data-name="Path 218"
          transform="rotate(167 660.698 558.386)"
          fill="#b4cd94"
        />
        <path
          id="Path_219"
          d="M0 2.377S52.864 240.1 73.9 259.315C73.9 258.518 6.816 0 6.816 0z"
          data-name="Path 219"
          transform="rotate(167 654.708 557.323)"
          fill="#405036"
        />
        <path
          id="Path_221"
          d="M7.265 0L0 46.645"
          className="cls-3-leaf-error-11"
          data-name="Path 221"
          transform="rotate(-172 732.823 411.569)"
        />
        <path
          id="Path_222"
          d="M36.215 0L0 70.134"
          className="cls-3-leaf-error-11"
          data-name="Path 222"
          transform="rotate(83 208.264 1202.357)"
        />
      </g>
    </svg>
  );
};

export default Error11;
