import React from 'react';

const H178R = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="170" height="178">
      <path
        d="M82.334 176.052l5.501-173.67"
        data-name="Path 217"
        fill="none"
        stroke="#405036"
        strokeWidth="5"
      />
    </svg>
  );
};

export default H178R;
