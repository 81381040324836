import React from 'react';

const H150 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="142" height="150">
      <path
        d="M68.614 146.204L73.17 2.38"
        data-name="Path 217"
        fill="none"
        stroke="#405036"
        strokeWidth="5"
      />
    </svg>
  );
};

export default H150;
