import React from 'react';
import PropTypes from 'prop-types';

const Cat = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-2-cat-svg {
          stroke: #000;
          stroke-miterlimit: 10;
          stroke-width: 5px;
          fill: none;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="162.124"
      height="164.669"
    >
      <defs />
      <g id="Group_132" data-name="Group 132" transform="translate(-893.967 -1738.677)">
        <path
          id="Path_2"
          d="M1053.592 1903.301c-1.948-109.02-22.689-160.4-22.689-160.4l-31.6 28.038c-24.11-3.894-54.166 0-54.166 0l-29.345-24.925c-20.229 36.64-19.3 125.545-19.3 125.545"
          data-name="Path 2"
          fill="#fff"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_3"
          d="M827.74 37.46l17.82-4.93-15.45-25.75"
          className="cls-2-cat-svg"
          data-name="Path 3"
          transform="translate(85.615 1739.109)"
        />
        <path
          id="Path_4"
          d="M944.7 3.79l-17.98 29.5 19.72 5.69"
          className="cls-2-cat-svg"
          data-name="Path 4"
          transform="translate(85.572 1739.109)"
        />
      </g>
    </svg>
  );
};

Cat.propTypes = {
  className: PropTypes.string,
};

Cat.defaultProps = {
  className: '',
};

export default Cat;
