import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles from './styles';

const Button = styled.button`
  ${styles}
  width: 150px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background: ${({ theme, secondary }) => (secondary ? theme.colors.white : theme.colors.green)};
  border: ${({ theme, secondary }) => (secondary ? `solid 3px ${theme.colors.black}` : 'none')};
  color: ${({ secondary, theme }) => (secondary ? theme.colors.black : theme.colors.white)};
  box-sizing: border-box;

  &:hover {
    background: ${({ theme, secondary }) => (secondary ? 'none' : theme.colors.black)};
    color: ${({ secondary, theme }) => (secondary ? theme.colors.green : theme.colors.white)};
    border: ${({ theme, secondary }) => (secondary ? `solid 3px ${theme.colors.green}` : 'none')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    width: ${({ small }) => (small ? '150px' : '190px')};
  }
`;

Button.propTypes = {
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  chosen: PropTypes.bool,
};

Button.defaultProps = {
  secondary: false,
  small: false,
  chosen: false,
  display: 'flex',
};

export default Button;
