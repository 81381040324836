import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import languages from '../i18n/languages';
import PageContext from '../layout/PageContext';

import Button from './Button';

const langLine = ({ theme, white, chosen }) =>
  chosen && `2px solid ${white ? theme.colors.white : theme.colors.green}`;

const StyledButton = styled(Button.Text)`
  padding: 0 10px;
  margin: 0;
  text-transform: uppercase;

  &:first-of-type {
    padding-left: 0;
    border-right: ${langLine};
  }

  &:last-of-type {
    border-left: ${langLine};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    margin: 0;
  }
`;

const Langs = ({ intl: { locale }, className, white }) => {
  const pageContext = useContext(PageContext);

  const handleSetLang = language => {
    const { originalPath } = pageContext.page;
    const newPathname = `/${language}${originalPath}`;

    localStorage.setItem('language', language);
    navigate(newPathname);
  };

  if (!pageContext.custom.localeKey) return null;
  return (
    <div className={className}>
      {languages.map(language => (
        <StyledButton
          key={language.locale}
          chosen={language.locale === locale}
          onClick={() => handleSetLang(language.locale)}
          white={white}
        >
          {language.label}
        </StyledButton>
      ))}
    </div>
  );
};

Langs.propTypes = {
  white: PropTypes.bool,
  className: PropTypes.string,
};

Langs.defaultProps = {
  white: false,
  className: '',
};

export default injectIntl(Langs);
