import flatten from 'flat';

import error404 from './404';
import home from './home';
import footer from './footer';
import nav from './nav';
import aboutUs from './aboutUs';
import menu from './menu';
import contact from './contact';

const message = { error404, home, nav, footer, aboutUs, menu, contact };

export default flatten(message);
