import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-1 {
          fill: ${props => props.theme.colors.green};
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
    >
      <defs />
      <g id="hamburger" transform="translate(-36 -36)">
        <path
          id="Rectangle_27"
          d="M0 0h14.17v3H0z"
          className="cls-1"
          data-name="Rectangle 27"
          transform="translate(38.915 36)"
        />
        <path
          id="Rectangle_28"
          d="M0 0h20v3H0z"
          className="cls-1"
          data-name="Rectangle 28"
          transform="translate(36 42)"
        />
        <path
          id="Rectangle_29"
          d="M0 0h14.17v3H0z"
          className="cls-1"
          data-name="Rectangle 29"
          transform="translate(38.915 48)"
        />
      </g>
    </svg>
  );
};

Hamburger.propTypes = {
  className: PropTypes.string,
};

Hamburger.defaultProps = {
  className: '',
};

export default Hamburger;
