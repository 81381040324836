import React from 'react';

const H210 = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="200" height="210">
      <path
        d="M103.505 208.438L97.274 2.334"
        data-name="Path 217"
        fill="none"
        stroke="#405036"
        strokeWidth="5"
      />
    </svg>
  );
};

export default H210;
