import React from 'react';
import PropTypes from 'prop-types';

const Eyes = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-1-cat-svg {
          fill: none;
          stroke: #000;
          stroke-miterlimit: 10;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="116.781"
      height="15.997"
    >
      <defs />
      <g id="Group_131" data-name="Group 131" transform="translate(-910.964 -1791.537)">
        <path
          id="Path_5"
          d="M869.93 67.2a24.48 24.48 0 0 0-42.41 0"
          className="cls-1-cat-svg"
          data-name="Path 5"
          transform="translate(85.61 1739.085)"
        />
        <path
          id="Path_6"
          d="M940 67.2a24.48 24.48 0 0 0-42.41 0"
          className="cls-1-cat-svg"
          data-name="Path 6"
          transform="translate(85.58 1739.085)"
        />
      </g>
    </svg>
  );
};

Eyes.propTypes = {
  className: PropTypes.string,
};

Eyes.defaultProps = {
  className: '',
};

export default Eyes;
