import React from 'react';

const Error9 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-9 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="145.073"
      height="273.974"
    >
      <defs />
      <g id="Group_209" data-name="Group 209" transform="translate(-1123.257 -684.416)">
        <path
          id="Path_218"
          d="M0 239.592S24.087 111.3 82.715 0c-1.637 3.495 41.693 132.106-51.475 249.13.052.27-31.24-9.538-31.24-9.538z"
          data-name="Path 218"
          transform="rotate(13 -2460.691 5522.834)"
          fill="#7ec323"
        />
        <path
          id="Path_219"
          d="M0 256.938S52.865 19.211 73.9 0c0 .8-67.084 259.315-67.084 259.315z"
          data-name="Path 219"
          transform="rotate(13 -2406.693 5580.5)"
          fill="#405036"
        />
        <path
          id="Path_221"
          d="M7.265 46.645L0 0"
          className="cls-3-leaf-error-9"
          data-name="Path 221"
          transform="rotate(-8 6714.444 -7761.978)"
        />
        <path
          id="Path_223"
          d="M14.55 15.14L0 0"
          className="cls-3-leaf-error-9"
          data-name="Path 223"
          transform="rotate(13 -2814.823 5657.394)"
        />
        <path
          id="Path_224"
          d="M.109 22.147L0 0"
          className="cls-3-leaf-error-9"
          data-name="Path 224"
          transform="rotate(76 154.875 1175.122)"
        />
        <path
          id="Path_222"
          d="M36.215 70.134L0 0"
          className="cls-3-leaf-error-9"
          data-name="Path 222"
          transform="rotate(97 251.012 972.308)"
        />
      </g>
    </svg>
  );
};

export default Error9;
