import React from 'react';

const Error1 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-3-leaf-error-1 {
          fill: none;
          stroke: #405036;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="171.134"
      height="323.19"
    >
      <defs />
      <g id="Group_217" data-name="Group 217" transform="translate(-298.349 -700.328)">
        <path
          id="Path_218"
          d="M0 11.258S28.413 162.592 97.574 293.89C95.643 289.767 146.755 138.053 36.852.007 36.913-.314 0 11.258 0 11.258z"
          data-name="Path 218"
          transform="rotate(167 177.815 526.38)"
          fill="#82c030"
        />
        <path
          id="Path_219"
          d="M0 2.8s62.361 280.435 87.177 303.1C87.177 304.957 8.041 0 8.041 0z"
          data-name="Path 219"
          transform="rotate(167 170.749 525.127)"
          fill="#405036"
        />
        <path
          id="Path_221"
          d="M8.57 0L0 55.024"
          className="cls-3-leaf-error-1"
          data-name="Path 221"
          transform="rotate(-172 251.32 465.115)"
        />
        <path
          id="Path_223"
          d="M17.164 0L0 17.86"
          className="cls-3-leaf-error-1"
          data-name="Path 223"
          transform="rotate(167 144.005 435.962)"
        />
        <path
          id="Path_224"
          d="M.128 0L0 26.125"
          className="cls-3-leaf-error-1"
          data-name="Path 224"
          transform="rotate(104 -133.755 517.824)"
        />
        <path
          id="Path_222"
          d="M42.721 0L0 82.733"
          className="cls-3-leaf-error-1"
          data-name="Path 222"
          transform="rotate(83 -291.909 667.332)"
        />
      </g>
    </svg>
  );
};

export default Error1;
