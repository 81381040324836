import React from 'react';
import PropTypes from 'prop-types';

const WavesH2 = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        path {
          fill: transparent;
          stroke: #000;
          stroke-miterlimit: 10;
          stroke-width: 5px;
          opacity: 0.152;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="344.35"
      height="38.288"
    >
      <defs />
      <g
        id="FALE_POD_NAPISEM"
        data-name="FALE POD NAPISEM"
        transform="translate(-787.649 -523.747)"
      >
        <path
          id="Path_116"
          d="M409.507 15.788C391.7 15.788 391.7 2.5 373.9 2.5s-17.8 13.288-35.607 13.288S320.49 2.5 302.687 2.5s-17.8 13.288-35.607 13.288S249.276 2.5 231.473 2.5s-17.8 13.288-35.607 13.288S178.063 2.5 160.259 2.5s-17.8 13.288-35.607 13.288S106.849 2.5 89.045 2.5c-11.991 0-15.9 6.031-22.625 9.964"
          className="cls-1"
          data-name="Path 116"
          transform="translate(722.492 533.747)"
        />
        <path
          id="Path_117"
          d="M409.507 15.788C391.7 15.788 391.7 2.5 373.9 2.5s-17.8 13.288-35.607 13.288S320.49 2.5 302.687 2.5s-17.8 13.288-35.607 13.288S249.276 2.5 231.473 2.5s-17.8 13.288-35.607 13.288S178.063 2.5 160.259 2.5s-17.8 13.288-35.607 13.288S106.849 2.5 89.045 2.5c-11.991 0-15.9 6.031-22.625 9.964"
          className="cls-1"
          data-name="Path 117"
          transform="translate(722.492 523.747)"
        />
        <path
          id="Path_118"
          d="M409.507 15.788C391.7 15.788 391.7 2.5 373.9 2.5s-17.8 13.288-35.607 13.288S320.49 2.5 302.687 2.5s-17.8 13.288-35.607 13.288S249.276 2.5 231.473 2.5s-17.8 13.288-35.607 13.288S178.063 2.5 160.259 2.5s-17.8 13.288-35.607 13.288S106.849 2.5 89.045 2.5c-11.991 0-15.9 6.031-22.625 9.964"
          className="cls-1"
          data-name="Path 118"
          transform="translate(722.492 543.747)"
        />
      </g>
    </svg>
  );
};

WavesH2.propTypes = {
  className: PropTypes.string,
};

WavesH2.defaultProps = {
  className: '',
};

export default WavesH2;
