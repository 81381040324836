import React from 'react';

const Bottom = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-2-waves-bottom {
          fill: transparent;
          stroke: #000;
          stroke-miterlimit: 10;
          stroke-width: 5px;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="1921.167"
      height="156.856"
    >
      <defs />
      <g id="Group_128" data-name="Group 128" transform="translate(.937 -1865.544)">
        <path
          id="Path_257"
          d="M8272 2265.018s66.879-43.256 133.572-38.879 99.979 28.533 99.979 28.533 44.213 29.787 112.17 22.283 79.676-25.6 94.33-30.428 76.422-35.75 158.527-9.83c7.541 2.5 63.941 31.037 63.941 31.037s63.98 21.43 121.2 4.264 81.625-43.324 126.215-44.518 81.727 3.58 101.621 12.732 48.943 33.094 93.09 35.766 72.963 3.15 116.857-15.437 60.148-40.529 137.074-33.061 70.369 28.842 122.537 43.166 93.965 7.873 128.385-4.291 60.7-37.277 109.516-38.875 61.2-1.846 98.715 12.99 63.916 37.916 101.811 36.551c-.947.8 0 98.449 0 98.449L8272 2380.4z"
          data-name="Path 257"
          transform="translate(-8272 -358)"
          fill="#fff"
        />
        <path
          id="Path_107"
          d="M1985.69 53.4c-77.58-7.462-85.506-50.9-177.674-50.9-100.84 0-100.84 52-201.681 52s-100.84-52-201.681-52-100.84 52-201.681 52-100.84-52-201.681-52-100.84 52-201.68 52-100.84-52-201.68-52-100.84 52-201.681 52-100.84-52-201.681-52c-67.916 0-90.084 23.6-128.151 38.993"
          className="cls-2-waves-bottom"
          data-name="Path 107"
          transform="translate(-66.42 1865.544)"
        />
        <path
          id="Path_108"
          d="M1985.69 53.4c-77.58-7.462-85.506-50.9-177.674-50.9-100.84 0-100.84 52-201.681 52s-100.84-52-201.681-52-100.84 52-201.681 52-100.84-52-201.681-52-100.84 52-201.68 52-100.84-52-201.68-52-100.84 52-201.681 52-100.84-52-201.681-52c-67.916 0-90.084 23.6-128.151 38.993"
          className="cls-2-waves-bottom"
          data-name="Path 108"
          transform="translate(-66.42 1911.522)"
        />
        <path
          id="Path_113"
          d="M1985.69 53.4c-77.58-7.462-85.506-50.9-177.674-50.9-100.84 0-100.84 52-201.681 52s-100.84-52-201.681-52-100.84 52-201.681 52-100.84-52-201.681-52-100.84 52-201.68 52-100.84-52-201.68-52-100.84 52-201.681 52-100.84-52-201.681-52c-67.916 0-90.084 23.6-128.151 38.993"
          className="cls-2-waves-bottom"
          data-name="Path 113"
          transform="translate(-66.42 1957.5)"
        />
        <path
          id="Path_110"
          d="M1986.42 53.472c-78.259-7.246-85.934-50.972-178.4-50.972-100.84 0-100.84 52-201.681 52s-100.84-52-201.681-52-100.84 52-201.681 52-100.84-52-201.681-52-100.84 52-201.68 52-100.84-52-201.681-52-100.84 52-201.681 52-100.84-52-201.681-52c-67.916 0-90.084 23.6-128.151 38.993"
          className="cls-2-waves-bottom"
          data-name="Path 110"
          transform="translate(-66.42 1887.538)"
        />
        <path
          id="Path_112"
          d="M1985.69 53.4c-77.58-7.462-85.506-50.9-177.674-50.9-100.84 0-100.84 52-201.681 52s-100.84-52-201.681-52-100.84 52-201.681 52-100.84-52-201.681-52-100.84 52-201.68 52-100.84-52-201.68-52-100.84 52-201.681 52-100.84-52-201.681-52c-67.916 0-90.084 23.6-128.151 38.993"
          className="cls-2-waves-bottom"
          data-name="Path 112"
          transform="translate(-66.42 1933.5)"
        />
      </g>
    </svg>
  );
};

export default Bottom;
