import React from 'react';

import { Flex } from '@rebass/grid';
import { zIndex, top, display } from 'styled-system';

import Waves from '../assets/waves/bottom';
import Cat from '../assets/cat';

import { upAndDown, moveArm, moveArmMobile } from '../styles/animations';

const Face = () => (
  <Flex
    css={`
      position: absolute;
      flex-direction: column;
      align-items: center;
      top: 50px;
      left: 79px;
    `}
  >
    <Cat.Eyes />
    <Cat.Nose
      css={`
        ${zIndex}
        position: relative;
        top: 8px;
      `}
      zIndex={5}
    />
    <Cat.Mouth
      css={`
        ${zIndex}
        position: relative;
        animation: ${upAndDown('-4px')} 0.8s infinite;
      `}
      zIndex={4}
    />
    <Cat.Pasta
      css={`
        ${zIndex}
        position: relative;
        top: -17px;
      `}
      zIndex={3}
    />
  </Flex>
);

const CatOnWaves = ({ className }) => {
  return (
    <Flex
      className={className}
      justifyContent="center"
      css={`
        position: relative;
      `}
      mt={235}
    >
      <Flex
        css={`
          ${top}
          position: absolute;
        `}
        top={[-120, -125]}
      >
        <Cat.RightArm
          css={`
            position: relative;
            left: 4px;
            animation: ${moveArmMobile} 3s infinite;

            @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
              animation: ${moveArm(true)} 1.5s infinite ease-in-out;
            }
          `}
        />
        <Cat />
        <Face />
        <Cat.LeftArm
          css={`
            ${display}
            position: relative;
            right: 6px;
            animation: ${moveArm()} 1.5s infinite ease-in-out;
          `}
          display={['none', 'block']}
        />
      </Flex>
      <Waves css={zIndex} zIndex={2} />
    </Flex>
  );
};

export default CatOnWaves;
